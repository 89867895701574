import React from 'react'

import { ExternalFont } from './external-font'

export function Fonts() {
  return (
    <>
      <ExternalFont
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap"
        rel="stylesheet"
      />
      <ExternalFont
        href="https://use.typekit.net/uzh6xln.css"
        rel="stylesheet"
      />
    </>
  )
}

// for @loadable/components
export default Fonts
